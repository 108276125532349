@import '_var';

@font-face {
  font-family: $fontPrimary;
  src: url('../assets/fonts/HKGrotesk-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: $fontPrimary;
  src: url('../assets/fonts/HKGrotesk-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: $fontPrimary;
  src: url('../assets/fonts/HKGrotesk-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: $fontPrimary;
  src: url('../assets/fonts/HKGrotesk-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: $fontPrimary;
  src: url('../assets/fonts/HKGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: $fontPrimary;
  src: url('../assets/fonts/HKGrotesk-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}
