@import '_var';

@mixin clearListStyles {
  margin: 0;
  padding: 0;
  list-style: none;
}

@mixin clearLinkStyles {
  text-decoration: none;
}
