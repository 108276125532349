@import '_var';
@import '_fonts';
@import '_mixin';
@import '_components';

* {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  background-color: $backgroundColor;
  margin: 0;
  padding: 0;
  width: 100%;
}

img {
  width: 100%;
}

.wrapper {
  width: 700px;
  max-width: 700px;
  height: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.works-wrapper {
  padding: 0 2rem;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.footer-wrapper--home {
  padding: 0 2rem;
  margin-bottom: 44px;
}

// main

.bg-line {
  position: absolute;
  z-index: 0;
  top: 0;
  width: 62%;
  left: 19%;
  height: 100%;
}

.bg-line__img {
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.bg {
  display: block;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
  z-index: 2;
  overflow: hidden;

  &__box {
    overflow: hidden;
    position: relative;
    right: 0;
  }
  &__box-1 {
    height: 6vh;
    top: 0;
  }
  &__box-2 {
    height: 16vh;
    top: 5vh;
  }
  &__box-3 {
    height: 8vh;
    top: 12vh;
  }

  &__img-dev,
  &__img-art,
  &__img-des {
    position: absolute;
    z-index: 2;
    right: 0;
  }
  &__img-dev {
    transform: translate(0, -155%);
  }
  &__img-art {
    transform: translate(0, -155%);
  }
  &__img-des {
    transform: translate(0, -155%);
  }
}

.bg__box-1 .bg__img-dev {
  animation: txt_anime 20s ease 1s infinite;
}

.bg__box-1 .bg__img-art {
  animation: txt_anime 20s ease 7.5s infinite;
}

.bg__box-1 .bg__img-des {
  animation: txt_anime 20s ease 14s infinite;
}

.bg__box-2 .bg__img-dev {
  animation: txt_anime 20s ease 1.2s infinite;
}

.bg__box-2 .bg__img-art {
  animation: txt_anime 20s ease 7.8s infinite;
}

.bg__box-2 .bg__img-des {
  animation: txt_anime 20s ease 14.5s infinite;
}

.bg__box-3 .bg__img-dev {
  animation: txt_anime 20s ease 1.6s infinite;
}

.bg__box-3 .bg__img-art {
  animation: txt_anime 20s ease 8s infinite;
}

.bg__box-3 .bg__img-des {
  animation: txt_anime 20s ease 14.8s infinite;
}

@keyframes txt_anime {
  0% {
    opacity: 0;
    transform: translate(0, -155%);
  }
  1% {
    opacity: 1;
  }
  20%,
  34% {
    opacity: 1;
    transform: translate(0, -50%);
  }
  50% {
    opacity: 1;
  }
  51%,
  100% {
    opacity: 0;
    transform: translate(0, 100%);
  }
}

.bg__box picture,
.bg__box img {
  display: block;
  width: auto;
}

.main {
  position: relative;
  padding: 0 0 2rem 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #f7f7f8;
  height: 20rem;

  &__header {
    padding: 24px 0px;
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  &__logo {
    @include clearLinkStyles;
    font: $font-size-body-s $fontPrimary;
    color: $blackColor;
    font-weight: 400;
  }
  &__social-link {
    @include clearLinkStyles;
  }
  &__link-txt {
    font: $font-size-body-s $fontPrimary;
    color: $blackColor;
    font-weight: 400;
  }
  &__content {
    position: relative;
    padding: 0;
  }
  &__title,
  &__descr {
    font-family: $fontPrimary;
    color: $blackColor;
    margin: 0;
  }
  &__title {
    font-size: $font-size-h1;
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 12px;
  }
  &__descr {
    font-size: $font-size-body-s;
    line-height: 19px;
    letter-spacing: 0.2px;
    font-weight: 400;
    width: 100%;
    max-width: 450px;
  }
  &__bank-link {
    display: inline-block;
    color: $blackColor;
    text-decoration: none;
  }
  &__bank-link::after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: $blackColor;
    transition: width .7s;
  }
  &__bank-link:hover::after {
    width: 0%;
  }
}

.main__social-link {
  display: inline-block;
  overflow: hidden;
  position: relative;
}

.main__social-link span {
  opacity: 1;
  transform: translate(0, 0);
  transition: all .3s ease-in-out;
}

.main__social-link:hover span {
  opacity: 1;
  transform: translate(0, -100%);
  transition: all .3s ease-in-out;
}

.main__link-txt {
  display: inline-block;
}

.main__social-link span::after {
  content: attr(data-txt);
  width: 100%;
  display: inline-block;
  left: 0;
  top: 100%;
  position: absolute;
  opacity: 1;
  background: $whiteColor;
  font-family: $fontPrimary;
  color: $blackColor;
  transform: translate(0, -100%);
}

.main__social-link:hover span::after {
  opacity: 1;
  transform: translate(0,0);
  // transition: all .3s ease-in-out;
}

.main__social-block {
  display: flex;
  @include clearListStyles;
}

.main__social-item:not(:last-child) {
  margin-right: 32px;
}

// works

.works {
  margin-bottom: 100px;
  transition-duration: 0.8s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: 0s;
  transition-property: all;
}

.works__item {
  min-height: 100px;
  position: relative;
  overflow: hidden;
}

.works__item-content {
  position: absolute;
  inset: 0;
}

.works__item-media {
  background-color: $gray100;
  border: none;
  width: 100%;
  height: 100%;
  position: relative;
}

.works__item-content,
.works__item a {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}

.works__item-link {
  @include clearListStyles;
}

.works__item-img {
  position: absolute;
  z-index: 0;
  height: inherit;
  object-fit: cover;
  transition: transform 0.8s;
}

.works__item:hover .works__item-img{
  transform: scale(1.05);
}

.works__item-placeholder {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
  transition: transform 0.8s;
}

.works__item-placeholder-name {
  position: absolute;
  bottom: 1rem;
  font-family: $fontPrimary;
  color: $gray300;
  margin: 0;
  font-size: $font-size-body-xs;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.2px;
}

.works__item:hover .works__item-placeholder-name {
  transform: translate(10%, -10%);
  transition-duration: 2s;
  transition-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  transition-delay: 0s;
  transition-property: all;
}

.works__item-description {
  background-color: $whiteColor;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 6px 0px;
  z-index: 1;
}

.works {
  &__item-title,
  &__item-subtitle {
    font-family: $fontPrimary;
    color: $blackColor;
    margin: 0;
    font-size: $font-size-body-xs;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.2px;
  }
  &__item-subtitle {
    color: $gray300;
  }
}

// footer

.footer {

  &__block {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }

  &__title,
  &__copyright {
    font-family: $fontPrimary;
    color: $blackColor;
    margin: 0;
  }
  &__title {
    font-size: $font-size-h1;
    line-height: 25px;
    font-weight: 500;
    margin-bottom: 16px;
  }
  &__social-block {
    @include clearListStyles;
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 0 24px 0;
  }
  &__social-item {
    flex-grow: 1;
    transition: 0.8s ease;
  }
  &__social-item:not(:last-child) {
    margin-right: 1rem;
  }
  &__social-item:hover {
    flex-grow: 1.5;
  }
  &__social-link {
    display: block;
    padding: 32px 0px;
    background-color: $gray100;
    text-align: center;
    @include clearLinkStyles;
    font: $font-size-body-s $fontPrimary;
    color: $blackColor;
    font-weight: 400;
  }
  &__copyright-block {
    display: flex;
    width: 100%;
    justify-content: flex-end;
  }
  &__copyright {
    font-size: $font-size-body-xs;
    line-height: 25px;
    letter-spacing: 0.2px;
    font-weight: 400;
  }
}

// media

@media only screen and (max-width: 1199px) {
  .works {
    &__item-title,
    &__item-subtitle {
      font-size: $font-size-body-xs;
      line-height: 15px;
    }
  }
}

@media only screen and (max-width: 768px) {
  // .cursor {
  //   touch-action: none;
  // }

  .main {
    &__social {
      display: none;
    }
  }

  .wrapper {
    width: 80%;
  }

  .works-wrapper {
    padding: 0 1rem;
  }

  .footer-wrapper--home {
    padding: 0 1rem;
    margin-bottom: 22px;
  }

  .footer {
    &__social-item:not(:last-child) {
      margin-right: 0.8rem;
    }
  }
}

@media only screen and (max-width: 575px) {
  .works {
    &__item-title,
    &__item-subtitle {
      font-size: $font-size-body-xs;
      line-height: 15px;
    }
  }

  .footer {
    &__social-block {
      flex-direction: column;
    }
    &__social-item {
      width: 100%;
    }
    &__social-item:not(:last-child) {
      margin-right: 0;
    }
    &__social-item:not(:last-child) {
      margin-bottom: 0.6rem;
    }
  }
}
