@import '_var';
@import '_fonts';
@import '_mixin';


// cursor

.cursor {
  position: fixed;
  z-index: 100;
  background-color: $blackColor;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  top: 0;
  left: 0;
  pointer-events: none;

  &--large {
    @extend .cursor;
    height: 44px;
    width: 44px;
    background-color: $whiteColor;
    mix-blend-mode: difference;
  }
}
