@import '_var';
@import '_mixin';

@for $i from 1 through 30 {
  .works__item:nth-child(#{$i}) {
    grid-area: area-#{$i};
  }
}

.works__list {
  @include clearListStyles;
  display: grid;
  margin: 0 auto;
  grid-gap: 1.4rem;
  grid-template-areas:
    'area-1 area-2 area-3 area-4 area-4'
    'area-1 area-5 area-5 area-6 area-7'
    'area-8 area-5 area-5 area-9 area-7';
}

.works__item:nth-child(1),
.works__item:nth-child(7),
.works__item:nth-child(5) {
  padding-bottom: 80%;
}
.works__item:nth-child(2),
.works__item:nth-child(3),
.works__item:nth-child(4),
.works__item:nth-child(6),
.works__item:nth-child(9) {
  padding-bottom: 40%;
}
.works__item:nth-child(14) {
  display: block;
}

@media only screen and (max-width: 1199px) {
  .works__list {
    grid-gap: 1.2rem;
    grid-template-areas:
      'area-1 area-2 area-3 area-3'
      'area-4 area-5 area-6 area-7'
      'area-4 area-8 area-8 area-9';
  }

  .works__item:nth-child(4),
  .works__item:nth-child(5),
  .works__item:nth-child(6),
  .works__item:nth-child(7) {
    padding-bottom: 80%;
  }
  .works__item:nth-child(1),
  .works__item:nth-child(2),
  .works__item:nth-child(3),
  .works__item:nth-child(8),
  .works__item:nth-child(9) {
    padding-bottom: 40%;
  }
}

@media only screen and (max-width: 991px) {
  .works__list {
    grid-gap: 1rem;
    grid-template-areas:
      'area-1 area-2 area-3'
      'area-4 area-5 area-6'
      'area-7 area-8 area-9';
  }
  .works__item:nth-child(1),
  .works__item:nth-child(2),
  .works__item:nth-child(3),
  .works__item:nth-child(4),
  .works__item:nth-child(5),
  .works__item:nth-child(6),
  .works__item:nth-child(7),
  .works__item:nth-child(8),
  .works__item:nth-child(9) {
    padding-bottom: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .works__list {
    grid-gap: 0.8rem;
    grid-template-areas:
      'area-1 area-2'
      'area-3 area-4'
      'area-5 area-5'
      'area-5 area-5'
      'area-6 area-7'
      'area-8 area-9';
  }
  .works__item:nth-child(1),
  .works__item:nth-child(2),
  .works__item:nth-child(3),
  .works__item:nth-child(4),
  .works__item:nth-child(5),
  .works__item:nth-child(6),
  .works__item:nth-child(8) {
    padding-bottom: 70%;
  }

  .works__item:nth-child(7),
  .works__item:nth-child(9) {
    padding-bottom: 70%;
  }
}

@media only screen and (max-width: 575px) {
  .works__list {
    grid-gap: 0.6rem;
    grid-template-areas:
      'area-1 area-2'
      'area-3 area-4'
      'area-5 area-5'
      'area-5 area-5'
      'area-6 area-7'
      'area-8 area-9';
  }
  .works__item:nth-child(1),
  .works__item:nth-child(2),
  .works__item:nth-child(3),
  .works__item:nth-child(4),
  .works__item:nth-child(5),
  .works__item:nth-child(6),
  .works__item:nth-child(8) {
    padding-bottom: 90%;
  }

  .works__item:nth-child(7),
  .works__item:nth-child(9) {
    padding-bottom: 90%;
  }
}
