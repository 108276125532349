@import '_var';
@import '_fonts';
@import '_mixin';
@import '_components';

* {
  box-sizing: border-box;
}

html,
body {
  scroll-behavior: smooth;
  background-color: $backgroundColor;
  margin: 0;
  padding: 0;
  width: 100%;
}

img {
  width: 100%;
}

.project {
  background-color: $whiteColor;
}

.full-screen-wrapper {
  padding: 0 1.8rem;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.content-wrapper {
  padding: 2rem;
  border-left: 1px solid #f7f7f8;
  border-right: 1px solid #f7f7f8;
  display: flex;
  justify-content: center;
}

.nav-wrapper {
  padding: 0 2rem;
  display: flex;
  justify-content: center;
}

.navigation__block {
  padding: 24px 0;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 700px;
  max-width: 700px;
}

.navigation {
  width: 100%;
  border-bottom: 1px solid $gray100;

  &__home-link {
    @include clearLinkStyles;
    font: $font-size-body-s $fontPrimary;
    color: $blackColor;
    font-weight: 400;
  }
  &__pages-links {
    display: flex;
    overflow: hidden;
  }
  &__pages-link{
    @include clearLinkStyles;
  }
  &__link-name {
    font: $font-size-body-s $fontPrimary;
    color: $blackColor;
    font-weight: 400;
    position: relative;
    top: -1px;
  }
  &__link-icon {
    min-width: 10px;
  }
  &__icon-prev {
    margin-right: 8px;
  }
  &__link-name-next {
    display: block;
    @include clearLinkStyles;
    margin-right: 8px;
  }
}

.navigation__pages-link:not(:last-child) {
  margin-right: 32px;
}

.navigation__pages-link {
  display: inline-flex;
  position: relative;
}

.navigation__pages-link span {
  opacity: 1;
  transform: translate(0, 0);
  transition: all .3s ease-in-out;
}

.navigation__pages-link:hover span {
  opacity: 1;
  transform: translate(0, -100%);
  transition: all .3s ease-in-out;
}

.navigation__link-name {
  display: inline-block;
}

.navigation__pages-link span::after {
  content: attr(data-txt);
  width: 100%;
  display: inline-block;
  left: 0;
  top: 100%;
  position: absolute;
  opacity: 1;
  background: $whiteColor;
  font-family: $fontPrimary;
  color: $blackColor;
  transform: translate(0, -100%);
}

.navigation__pages-link:hover span::after {
  opacity: 1;
  transform: translate(0,0);
  // transition: all .3s ease-in-out;
}

.project-hero {
  border-bottom: 1px solid #f7f7f8;

  &__block {
    padding: 3rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 700px;
    max-width: 700px;
  }
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__title,
  &__descr,
  &__responsibilities-title,
  &__responsibilities-descr {
    font-family: $fontPrimary;
    color: $blackColor;
    margin: 0;
  }
  &__title {
    width: 50%;
    font-size: $font-size-h1;
    line-height: 25px;
    font-weight: 500;
    margin-right: 2rem;
  }
  &__descr {
    font-size: $font-size-body-s;
    line-height: 19px;
    letter-spacing: 0.2px;
    font-weight: 400;
    margin-bottom: 1.8rem;
  }
  &__responsibilities-title {
    font-size: $font-size-body-s;
    line-height: 19px;
    letter-spacing: 0.2px;
    font-weight: 400;
    margin-bottom: 0.2rem;
  }
  &__responsibilities-descr {
    font-size: $font-size-body-s;
    line-height: 19px;
    letter-spacing: 0.2px;
    font-weight: 400;
    color: $gray300;
  }
}

.project-hero__descr ul {
  list-style-type: "\—  ";
  padding-left: 20px;
  margin-top: 0.5rem;
}

.project-result {
  border-bottom: 1px solid $gray100;
}

.footer-wrapper--project {
  padding: 3.6rem 2rem;
  border-left: 1px solid $gray100;
  border-right: 1px solid $gray100;
}



@media only screen and (max-width: 1199px) {

  .full-screen-wrapper {
    padding: 0 1.2rem;
  }

  .content-wrapper {
    padding: 1.2rem;
  }

  .nav-wrapper {
    padding: 0 1.2rem;
  }

  .footer-wrapper--project  {
    padding: 3rem 1.2rem;
  }
}

@media only screen and (max-width: 991px) {
  .full-screen-wrapper {
    padding: 0 1rem;
  }

  .content-wrapper {
    padding: 1rem;
  }

  .nav-wrapper {
    padding: 0 1rem;
  }

  .footer-wrapper--project  {
    padding: 2rem 1rem;
  }
}

@media only screen and (max-width: 575px) {
  .project-hero__block {
    flex-direction: column;
  }

  .project-hero__title {
    margin-right: 0;
    margin-bottom: 0.6rem;
  }
}


