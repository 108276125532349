// colors
$primaryColor: #c11f5b;
$backgroundColor: #ffffff;
$whiteColor: #ffffff;
$blackColor: #141414;
$gray100: #f6f7f8;
$gray200: #eeeef0;
$gray300: #999999;

// fonts
$fontPrimary: 'HKGrotesk';

// font sizes
$font-size-h1: 17px;
$font-size-h2: 15px;
$font-size-body-s: 13px;
$font-size-body-xs: 11px;

// border
$baseBorderRadius: 4px;
